*{
  box-sizing: border-box;
  padding: 0px;
  margin: 0;
}

.img {
  margin: 10px;
}

.text {
  padding: 10px 0px;
}

.icons {
  display: inline-flex;
  justify-content: space-between;
  /* position: absolute;
  bottom: 0;
  left: 0; */
margin-top: auto;
}

.icon{
  justify-content: space-between;
}

.main {
  /* max-width: fit-content; */
  width: 100%;
  /* padding: 0 20px; */
  background-color: rgba(245, 245, 245, 0.959);
  min-height: 90vh;
  margin: 20px 20px 0px 20px;
}

.comments{
    padding-top: 20px;
}

.comment-field{
    /* background-color: #357db3; */
    height: fit-content;
}


.input{
    width: 100%;
    /* padding: 0 30px; */
    border: none;
}

.footer{
  background-color: #357db3;
  min-height: 10vh;
  width: 100%;
}

.icon{
  cursor: pointer;
}

.two{
  padding: 5px 0px;
}


.like{
 cursor: pointer;
}

.button{
  color: #357db3;
  float: right;
  /* padding: 10px 15px;
  border-radius: 4px; */
  /* border: none; */
  /* color: white; */
}

.divv{
  background-color: #fff;
}